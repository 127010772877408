const tips = {
  state: {
    tips: [],
    tips_by_stop: {},
  },
  mutations: {
    ['ADD_TIP'](state, tip) {
      state.tips.push(tip);
      // Vue.set(state.trip_positions, trip.tid, state.trips.length - 1);
    },
  },
  actions: {
    addTip({
      commit
    }, tip) {
      commit('ADD_TIP', tip);
    },
  }
};

export default tips;
