import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

const Home = () => import('./views/Home.vue');
const Trip = () => import('./views/Trip.vue');
const Login = () => import('./views/Login.vue');
const Editor = () => import('./views/Editor.vue');
const EditTrip = () => import('./views/EditTrip.vue');
const EditStop = () => import('./views/EditStop.vue');
const Stop = () => import('./views/Stop.vue');
const NewTrip = () => import('./views/NewTrip.vue');
const Embed = () => import('./views/Embed.vue');
// import NewTip from './views/NewTip.vue';
const EmbedUser = () => import('./views/EmbedUser.vue');
const EmbedUserTrip = () => import('./views/EmbedUserTrip.vue');
const EmbedUserStop = () => import('./views/EmbedUserStop.vue');
const EmbedSetup = () => import('./views/EmbedSetup.vue');
const Export = () => import('./views/Export.vue');
const MapPreferences = () => import('./views/MapPreferences.vue');
const Share = () => import('./views/Share.vue');
const Styles = () => import('./views/Styles.vue');
const Standalone = () => import('./views/Standalone.vue');
const StandaloneUser = () => import('./views/StandaloneUser.vue');
const StandaloneUserTrip = () => import('./views/StandaloneUserTrip.vue');
const StandaloneUserStop = () => import('./views/StandaloneUserStop.vue');

Vue.use(Router);

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to, from, savedPosition) {
  return {
    x: 0,
    y: 0,
  };
};

const letQueryParamsOverridePreferences = (params) => {
  if (typeof params.tiles !== 'undefined') {
    store.dispatch('setBasetiles', params.tiles);
  }
  if (typeof params.showguide !== 'undefined') {
    store.dispatch('setIncludeGuide', params.showguide !== 'false');
  }
  if (typeof params.triponly !== 'undefined') {
    store.dispatch('setIncludeAllTripsLink', params.triponly == 'false');
  }
};

/*
 * Loads any preferences needed before proceeding
 */
const getPreferencesAndProceed = async (to, next) => {
  await store.dispatch('getMappingPreferences');
  next();
};

const onEnteringEditor = async (to, from, next) => {
  await store.dispatch('loginWithJWT');
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      await store.dispatch('setTripUserId', store.getters.userId);
      if (to.params.tripId) {
        await store.dispatch('setTripId', to.params.tripId);
      }
      store.dispatch('getTripJson');
      getPreferencesAndProceed(to, next);
    }
  } else {
    if (to.params.userId) {
      await store.dispatch('setTripUserId', to.params.userId);
    } else if (to.params.tripId) {
      await store.dispatch('setTripId', to.params.tripId);
      await store.dispatch('getTripUserId');
    }
    getPreferencesAndProceed(to, next);
  }
};

export default new Router({
  scrollBehavior,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      component: Editor,
      beforeEnter: onEnteringEditor,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/share',
          name: 'share',
          component: Share,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/styles',
          name: 'styles',
          component: Styles,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/user/:userId',
          name: 'userHome',
          component: Home,
        },
        {
          path: '/new',
          name: 'new',
          component: NewTrip,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/trip/:tripId',
          name: 'trip',
          component: Trip,
          beforeEnter: (to, from, next) => {
            store.dispatch('setTripId', to.params.tripId).then(() => {
              next();
            });
          },
        },
        {
          path: '/trip/:tripId/stop/:stopId',
          name: 'stop',
          component: Stop,
          beforeEnter: (to, from, next) => {
            store.dispatch('setTripId', to.params.tripId).then(() => {
              next();
            });
          },
        },
        {
          path: '/trip/:tripId/edit',
          name: 'edit-trip',
          component: EditTrip,
          meta: {
            requiresAuth: true,
          },
          beforeEnter: (to, from, next) => {
            store.dispatch('setTripId', to.params.tripId).then(() => {
              next();
            });
          },
        },
        {
          path: '/preferences',
          name: 'preferences',
          component: MapPreferences,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/trip/:tripId/stop/:stopId/edit',
          name: 'edit-stop',
          component: EditStop,
          meta: {
            requiresAuth: true,
          },
          beforeEnter: (to, from, next) => {
            store.dispatch('setTripId', to.params.tripId).then(() => {
              next();
            });
          },
        },
        {
          path: '/tripid/:tripId',
          redirect: {
            name: 'trip',
          },
        },
        {
          path: '/tripid/:tripId/stopid/:stopId',
          redirect: {
            name: 'stop',
          },
        },
      ],
    },
    {
      path: '/export',
      name: 'export',
      component: Export,
      beforeEnter: async (to, from, next) => {
        await store.dispatch('loginWithJWT');
        if (!store.getters.isLoggedIn) {
          next({
            path: '/login',
            query: {
              redirect: to.fullPath,
            },
          });
        } else if (!store.getters.isSupporter) {
          next();
        } else {
          await store.dispatch('getMappingPreferences');
          letQueryParamsOverridePreferences(to.query);
          next();
        }
      },
    },
    {
      path: '/embed/',
      name: 'embed',
      component: Embed,
      beforeEnter: (to, from, next) => {
        store.dispatch('setIsEmbedded', true);
        next();
      },
      children: [
        {
          path: '/embed/:userId',
          name: 'embeddedUser',
          component: EmbedUser,
          beforeEnter: async (to, from, next) => {
            const { userId } = to.params;
            await store.dispatch('setTripUserId', userId);
            const isSupporter = await store.dispatch('getIsSupporter', userId);
            if (!isSupporter) {
              next();
            } else {
              await store.dispatch('getMappingPreferences');
              letQueryParamsOverridePreferences(to.query);
              next();
            }
          },
        },
        {
          path: '/embed/:userId/:tripId',
          name: 'embeddedTrip',
          component: EmbedUserTrip,
          beforeEnter: async (to, from, next) => {
            const { userId } = to.params;
            const { tripId } = to.params;
            await store.dispatch('setTripUserId', userId);
            await store.dispatch('setTripId', tripId);
            const isSupporter = await store.dispatch('getIsSupporter', userId);
            if (!isSupporter) {
              next();
            } else {
              await store.dispatch('getMappingPreferences');
              letQueryParamsOverridePreferences(to.query);
              next();
            }
          },
        },
        {
          path: '/embed/:userId/:tripId/:stopId',
          name: 'embeddedStop',
          component: EmbedUserStop,
          beforeEnter: async (to, from, next) => {
            const { userId } = to.params;
            const { tripId } = to.params;
            await store.dispatch('setTripUserId', userId);
            await store.dispatch('setTripId', tripId);
            const isSupporter = await store.dispatch('getIsSupporter', userId);
            if (!isSupporter) {
              next();
            } else {
              await store.dispatch('getMappingPreferences');
              letQueryParamsOverridePreferences(to.query);
              next();
            }
          },
        },
      ],
    },
    {
      path: '/show/',
      name: 'standalone',
      component: Standalone,
      beforeEnter: (to, from, next) => {
        // store.dispatch('setIsEmbedded', true);
        next();
      },
      children: [
        {
          path: '/show/:userId',
          name: 'standaloneUser',
          component: StandaloneUser,
          beforeEnter: async (to, from, next) => {
            const { userId } = to.params;
            await store.dispatch('setTripUserId', userId);
            const isSupporter = await store.dispatch('getIsSupporter', userId);
            if (!isSupporter) {
              next();
            } else {
              await store.dispatch('getMappingPreferences');
              letQueryParamsOverridePreferences(to.query);
              next();
            }
          },
        },
        {
          path: '/show/:userId/:tripId',
          name: 'standaloneTrip',
          component: StandaloneUserTrip,
          beforeEnter: async (to, from, next) => {
            const { userId } = to.params;
            const { tripId } = to.params;
            await store.dispatch('setTripUserId', userId);
            await store.dispatch('setTripId', tripId);
            const isSupporter = await store.dispatch('getIsSupporter', userId);
            if (!isSupporter) {
              next();
            } else {
              await store.dispatch('getMappingPreferences');
              letQueryParamsOverridePreferences(to.query);
              next();
            }
          },
        },
        {
          path: '/show/:userId/:tripId/:stopId',
          name: 'standaloneStop',
          component: StandaloneUserStop,
          beforeEnter: async (to, from, next) => {
            const { userId } = to.params;
            const { tripId } = to.params;
            await store.dispatch('setTripUserId', userId);
            await store.dispatch('setTripId', tripId);
            const isSupporter = await store.dispatch('getIsSupporter', userId);
            if (!isSupporter) {
              next();
            } else {
              await store.dispatch('getMappingPreferences');
              letQueryParamsOverridePreferences(to.query);
              next();
            }
          },
        },
      ],
    },
    {
      path: '/embed-setup/',
      name: 'embedSetup',
      component: EmbedSetup,
      beforeEnter: async (to, from, next) => {
        await store.dispatch('loginWithJWT');
        await store.dispatch('getMappingPreferences');
        await store.dispatch('getTripJson');
        next();
      },
    },
  ],
});
