/* 
  Track an event in Google Analytics
*/

export default function analyticsEvent(category, action, label, value) {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      'event' : 'GAEvent',
      'GAEventCategory' : category,
      'GAEventAction' : action,
      'GAEventLabel' : label,
      'GAEventValue' : value
    });
  }
}
