import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueCookie from 'vue-cookie';
import { format } from 'date-fns';
import distanceBetween from './helpers/distanceBetween.js';
import analyticsEvent from './helpers/analyticsEvent.js';
import targetLinksToNewTab from './helpers/targetLinksToNewTab.js';
import {
  getHalfwayCoordinates,
  stitchPolylines,
  decodePolyline,
  getCurvedLine,
  encodePolyline,
} from './helpers/polyline.js';
import tips from './modules/tips.js';
import length from '@turf/length';
import { lineString } from '@turf/helpers';

Vue.use(Vuex);
Vue.use(VueCookie);

export default new Vuex.Store({
  modules: {
    tips,
  },
  state: {
    user_id: 0,
    trip_user_id: 0, // for when viewing other people's trips
    is_supporter: false,
    username: '',
    trip_username: '', // for when viewing other people's trips
    error_message: '',
    trip_id: 0,
    stop_id: 0,
    is_embedded: false,
    hovered_trip_id: 0,
    hovered_stop_id: 0,
    trips: [],
    tripRoutesLoaded: {},
    stop_data: {},
    stop_trip_ids: {}, // quick lookup table for trip ids
    trip_positions: {},
    photo: {},
    adding_new_trip: false,
    new_trip: {},
    edit_trip_id: 0,
    edit_stop_id: 0,
    add_stops_to_trip_id: 0,
    editing: false,
    adding_stops: false,
    pending_stop: {},
    updated_trips: [],
    deleted_trips: [],
    geocoder_inited: false,
    loading_trips: false,
    loaded_trips: false,
    editing_routes: false,
    route_stop_index: -1,
    route_options: {},
    loading_routes: {},
    route_option_hovered: -1,
    is_saving: false,
    image_requested: false,
    has_pending_stop: false,
    map_starting_zoom: 1,
    map_starting_center: [0, 0],
    tiles: 'mapbox',
    distance_preference: 'k',
    current_location: 0,
    include_all_trips_link: true,
    include_guide: true,
    nearby_properties: [],
    loading_nearby_properties: false,
    line_width: 3,
    line_style: 1,
    show_all_lines: true,
    line_types_to_show: [],
    applied_preferences: {},
    custom_route: {
      coordinates: [],
    },
    refresh_required: false,
    creating_custom_route: false,
  },
  mutations: {
    SET_USER_ID(state, user_id) {
      state.user_id = parseInt(user_id, 10);
    },
    SET_TRIP_USER_ID(state, trip_user_id) {
      state.trip_user_id = parseInt(trip_user_id, 10);
    },
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_TRIP_USERNAME(state, trip_username) {
      state.trip_username = trip_username;
    },
    SET_IS_SUPPORTER(state, payload) {
      state.is_supporter = payload;
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.error_message = payload;
    },
    SET_INCLUDE_GUIDE(state, payload) {
      state.include_guide = payload == true;
    },
    SET_LINE_WIDTH(state, payload) {
      state.line_width = parseInt(payload, 10);
    },
    SET_LINE_STYLE(state, payload) {
      state.line_style = parseInt(payload, 10);
    },
    SET_SHOW_ALL_LINES(state, payload) {
      state.show_all_lines = payload == true;
    },
    SET_APPLIED_PREFERENCES(state, payload) {
      state.applied_preferences = payload;
    },
    SET_TRIP_ID(state, trip_id) {
      state.trip_id = parseInt(trip_id, 10);
    },
    SET_GEOCODER_INITED(state, status) {
      state.geocoder_inited = status;
    },
    SET_PHOTO(state, photo) {
      state.photo = photo;
    },
    SET_STOP_ID(state, stop_id) {
      state.stop_id = parseInt(stop_id, 10);
    },
    SET_IS_EMBEDDED(state, payload) {
      state.is_embedded = payload;
    },
    SET_STOP_TRIP_ID(state, stop_trip_id) {
      state.stop_trip_ids = Object.assign(
        state.stop_trip_ids,
        parseInt(stop_trip_id, 10)
      );
    },
    SET_HOVERED_TRIP_ID(state, trip_id) {
      state.hovered_trip_id = parseInt(trip_id, 10);
    },
    SET_HOVERED_STOP_ID(state, stop_id) {
      state.hovered_stop_id = parseInt(stop_id, 10);
    },
    SET_TRIP_ROUTES(state, data) {
      Vue.set(state.routes, parseInt(data.tripId, 10), data.stops);
    },
    SET_TRIP_ROUTES_LOADED(state, payload) {
      Vue.set(state.tripRoutesLoaded, payload, 1);
    },
    SET_ROUTE_OPTIONS(state, payload) {
      Vue.set(state.route_options, payload.key, payload.options);
    },
    SET_ROUTE_OPTION_HOVERED(state, routeOption) {
      state.route_option_hovered = routeOption;
    },
    SET_CURRENT_LOCATION(state, payload) {
      state.current_location = payload;
    },
    ADD_TRIPS(state, trips) {
      state.trips = trips;
    },
    ADD_TRIP_POSITIONS(state, trip_positions) {
      state.trip_positions = trip_positions;
    },
    ADD_STOP_DATA(state, newStopData) {
      state.stop_data = Object.assign(state.stop_data, newStopData);
    },
    ADDING_NEW_TRIP(state, adding_new_trip) {
      state.adding_new_trip = adding_new_trip;
    },
    ADD_NEW_TRIP(state, new_trip) {
      state.new_trip = new_trip;
    },
    SET_EDITING_TRIP(state, trip_id) {
      state.edit_trip_id = parseInt(trip_id, 10);
    },
    SET_EDITING_STOP(state, stop_id) {
      state.edit_stop_id = parseInt(stop_id, 10);
    },
    SET_EDITING_TRIPSTOPS(state, trip_id) {
      state.add_stops_to_trip_id = parseInt(trip_id, 10);
    },
    SET_ADDING_STOPS(state, isAddingStops) {
      state.adding_stops = isAddingStops;
    },
    ADD_TRIP_TO_TRIPS(state, trip) {
      state.trips.push(trip);
      Vue.set(
        state.trip_positions,
        parseInt(trip.tid, 10),
        state.trips.length - 1
      );
    },
    SET_EDITING_STATE(state, editing) {
      state.editing = editing;
    },
    SET_PENDING_STOP(state, stop) {
      state.pending_stop = stop;
    },
    SET_PENDING_STOP_COORDINATES(state, lngLat) {
      Vue.set(state.pending_stop, 'lng', lngLat[0]);
      Vue.set(state.pending_stop, 'lat', lngLat[1]);
    },
    SET_PENDING_STOP_NAME(state, name) {
      state.pending_stop.name = name;
    },
    SET_TRIP(state, trip) {
      Vue.set(state.trips, state.trip_positions[parseInt(trip.tid, 10)], trip);
    },
    SET_UPDATED_TRIPS(state, updatedTrips) {
      state.updated_trips = updatedTrips;
    },
    DELETE_TRIP(state, tripId) {
      const pos = state.trip_positions[tripId];
      state.trips.splice(pos, 1);
      delete state.trip_positions[tripId];
    },
    SET_DELETED_TRIPS(state, deletedTrips) {
      state.deleted_trips = deletedTrips;
    },
    SET_EDITING_ROUTES(state, editingRoutes) {
      state.editing_routes = editingRoutes;
    },
    SET_ROUTE_STOP_INDEX(state, routeStopIndex) {
      state.route_stop_index = routeStopIndex;
    },
    // Loading states
    SET_LOADING_TRIPS(state, loadingTrips) {
      state.loading_trips = loadingTrips;
    },
    SET_LOADED_TRIPS(state) {
      state.loaded_trips = true;
    },
    SET_LOADING_ROUTES(state, payload) {
      Vue.set(state.loading_routes, payload[0], payload[1]);
    },
    SET_IS_SAVING(state, isSaving) {
      state.is_saving = isSaving;
    },
    SET_HAS_PENDING_STOP(state, hasPendingStop) {
      state.has_pending_stop = hasPendingStop;
    },
    SET_MAP_STARTING_ZOOM(state, zoom) {
      state.map_starting_zoom = parseInt(zoom, 10);
    },
    SET_MAP_STARTING_CENTER(state, center) {
      state.map_starting_center = [
        parseFloat(center[0]),
        parseFloat(center[1]),
      ];
    },
    SET_DISTANCE_PREFERENCE(state, payload) {
      state.distance_preference = payload;
    },
    // Tileset
    SET_TILES(state, payload) {
      state.tiles = payload;
    },
    SET_INCLUDE_ALL_TRIPS_LINK(state, payload) {
      state.include_all_trips_link = payload;
    },
    SET_INCLUDE_GUIDE(state, payload) {
      state.include_guide = payload;
    },
    SET_NEARBY_PROPERTIES(state, payload) {
      state.nearby_properties = payload;
    },
    SET_LOADING_NEARBY_PROPERTIES(state, payload) {
      state.loading_nearby_properties = payload;
    },
    SET_IMAGE_REQUESTED(state, payload) {
      state.image_requested = payload;
    },
    SET_LINE_TYPES_TO_SHOW(state, payload) {
      state.line_types_to_show = payload;
    },
    SET_CUSTOM_ROUTE(state, payload) {
      state.custom_route = payload;
    },
    SET_CREATING_CUSTOM_ROUTE(state, payload) {
      state.creating_custom_route = payload;
    },
    SET_REFRESH_REQUIRED(state, payload) {
      state.refresh_required = payload;
    },
  },
  actions: {
    setUserId({ commit }, userId) {
      commit('SET_USER_ID', userId);
    },
    setTripUserId({ commit }, tripUserId) {
      commit('SET_TRIP_USER_ID', tripUserId);
    },
    setUsername({ commit }, username) {
      commit('SET_USERNAME', username);
    },
    setTripUsername({ commit }, tripUsername) {
      commit('SET_TRIP_USERNAME', tripUsername);
    },
    setErrorMessage({ commit }, message) {
      commit('SET_ERROR_MESSAGE', message);
    },
    setIsSupporter({ commit }, isSupporter) {
      commit('SET_IS_SUPPORTER', isSupporter);
    },
    setDistancePreference({ commit }, payload) {
      commit('SET_DISTANCE_PREFERENCE', payload);
    },
    getCurrentLocation({ getters, commit }) {
      // Loop through this trip's stops and find the first date that matches today
      if (getters.selectedTrip != null) {
        const today = format(new Date(), 'YYYY-MM-DD');
        getters.selectedTrip.ss.forEach((stop) => {
          if (stop.dd >= today && (stop.ad === '' || stop.ad <= today)) {
            commit('SET_CURRENT_LOCATION', stop.sid);
          }
        });
      }
    },
    loginWithUsernameAndPassword({ dispatch }, payload) {
      const formData = new FormData();
      formData.append('username', payload[0]);
      formData.append('password', payload[1]);
      return axios({
        method: 'post',
        url: '/ajax/AuthService.cfc?method=_login',
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        withCredentials: true,
      }).then((result) => {
        if (result.status === 200 && result.data.success) {
          dispatch('setUserId', result.data.data.userID);
          dispatch('setUsername', result.data.data.username);
          dispatch('setIsSupporter', !!result.data.data.isSupporter);
        } else {
          dispatch('setErrorMessage', result.data.messages[0]);
        }
      });
    },
    loginWithJWT({ dispatch, commit }) {
      return axios({
        url: '/ajax/AuthService.cfc?method=_loginWithJWT',
        headers: {
          Authorization: `Bearer ${this.getters.jwt}`,
        },
        withCredentials: true,
      }).then((result) => {
        if (result.status === 200 && result.data.success) {
          dispatch('setUserId', result.data.data.userID);
          dispatch('setUsername', result.data.data.username);
          dispatch('setIsSupporter', !!result.data.data.isSupporter);
        }
      });
    },
    getMappingPreferences({ dispatch, state }) {
      if (Object.keys(state.applied_preferences).length > 0) {
        return Promise.resolve();
      }
      const formData = new FormData();
      formData.append('userid', this.getters.tripUserId);
      return axios({
        method: 'post',
        url: '/ajax/UserService.cfc?method=_getMappingPreferences',
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        withCredentials: false,
      }).then((result) => {
        if (result.status === 200 && result.data.success) {
          dispatch('setIsSupporter', !!result.data.data.isSupporter);
          dispatch(
            'setIncludeAllTripsLink',
            result.data.data.includeAllTripsLink == 'true'
          );
          dispatch('setDistancePreference', result.data.data.distanceFormat);
          dispatch('setIncludeGuide', result.data.data.includeGuide == 'true');
          dispatch('setBasetiles', result.data.data.baseTiles);
          dispatch('setLineWidth', result.data.data.lineWidth);
          dispatch('setLineStyle', result.data.data.lineStyle);
          dispatch('setLineTypesToShow', result.data.data.lineTypesToShow);
          dispatch('setShowAllLines', result.data.data.showAllLines == 'true');
          dispatch('setAppliedPreferences', {
            tiles: result.data.data.baseTiles,
            includeGuide: result.data.data.includeGuide,
            includeAllTripsLink: result.data.data.includeAllTripsLink,
            lineWidth: result.data.data.lineWidth,
            lineStyle: result.data.data.lineStyle,
            lineTypesToShow: result.data.data.lineTypesToShow,
            showAllLines: result.data.data.showAllLines,
          });
        }

        return state.applied_preferences;
      });
    },
    saveStylePreferences({ dispatch, state }, payload) {
      dispatch('setIsSaving', true);

      const formData = new FormData();
      formData.append('baseTiles', state.tiles);
      formData.append('includeAllTripsLink', state.include_all_trips_link);
      formData.append('includeGuide', state.include_guide);
      formData.append('lineWidth', state.line_width);
      formData.append('lineStyle', state.line_style);
      formData.append('lineTypesToShow', state.line_types_to_show);
      formData.append('showAllLines', state.show_all_lines);
      return axios({
        method: 'post',
        url: '/ajax/UserService.cfc?method=_setMappingPreferences',
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        withCredentials: true,
      }).then((result) => {
        if (result.status === 200 && result.data.success) {
          dispatch('setIsSaving', false);
          dispatch('setAppliedPreferences', {});
        }
      });
    },
    setIncludeAllTripsLink({ commit }, payload) {
      commit('SET_INCLUDE_ALL_TRIPS_LINK', payload);
    },
    setIncludeGuide({ commit }, payload) {
      commit('SET_INCLUDE_GUIDE', payload);
    },
    setBasetiles({ commit }, payload) {
      commit('SET_TILES', payload);
    },
    setLineWidth({ commit }, payload) {
      commit('SET_LINE_WIDTH', parseInt(payload, 10));
    },
    setLineStyle({ commit }, payload) {
      commit('SET_LINE_STYLE', parseInt(payload, 10));
    },
    setLineTypesToShow({ commit }, payload) {
      commit('SET_LINE_TYPES_TO_SHOW', payload);
    },
    setShowAllLines({ commit }, payload) {
      commit('SET_SHOW_ALL_LINES', payload);
    },
    setAppliedPreferences({ commit }, payload) {
      commit('SET_APPLIED_PREFERENCES', payload);
    },
    setTripId({ commit, state, dispatch }, tripId) {
      commit('SET_TRIP_ID', parseInt(tripId, 10));
      commit('SET_ADDING_STOPS', false);
      commit('SET_EDITING_STATE', false);
    },
    setIsEmbedded({ commit }, payload) {
      commit('SET_IS_EMBEDDED', payload);
    },
    setHoveredTripId({ commit }, tripId) {
      commit('SET_HOVERED_TRIP_ID', tripId);
    },
    setHoveredStopId({ commit }, stopId) {
      commit('SET_HOVERED_STOP_ID', stopId);
    },
    setEditingState({ commit }, editing) {
      commit('SET_EDITING_STATE', editing);
    },
    setImageRequested({ commit }, requested) {
      commit('SET_IMAGE_REQUESTED', requested);
    },
    setPendingStop({ commit }, stop) {
      commit('SET_PENDING_STOP', stop);
      commit('SET_HAS_PENDING_STOP', true);
    },
    setPendingStopCoordinates({ commit, state }, lngLat) {
      commit('SET_PENDING_STOP_COORDINATES', lngLat);
    },
    cancelPendingStop({ commit }) {
      commit('SET_PENDING_STOP', {});
      commit('SET_HAS_PENDING_STOP', false);
    },
    setLoadingTrips({ commit }, loadingTrips) {
      commit('SET_LOADING_TRIPS', loadingTrips);
    },
    setLoadingRoutes({ commit }, payload) {
      commit('SET_LOADING_ROUTES', payload);
    },
    setIsSaving({ commit }, isSaving) {
      commit('SET_IS_SAVING', isSaving);
    },
    setRefreshRequired({ commit }, isRequired) {
      commit('SET_REFRESH_REQUIRED', isRequired);
    },
    setEditingRoutes({ commit, dispatch, state }, editingRoutes) {
      commit('SET_EDITING_ROUTES', editingRoutes);
      if (!editingRoutes) {
        commit('SET_ROUTE_STOP_INDEX', -1);
      } else {
        const originStop =
          this.getters.selectedTrip.ss[this.state.route_stop_index];
        const destinationStop =
          this.getters.selectedTrip.ss[this.state.route_stop_index + 1];
        dispatch('getRouteOptions', {
          from: [originStop.lng, originStop.lat],
          to: [destinationStop.lng, destinationStop.lat],
        });
      }
    },
    setRouteStopIndex({ commit }, routeStopIndex) {
      commit('SET_ROUTE_STOP_INDEX', routeStopIndex);
      if (routeStopIndex === -1) {
        commit('SET_EDITING_ROUTES', false);
      }
    },
    setRouteOptions({ commit }, payload) {
      commit('SET_ROUTE_OPTIONS', payload);
    },
    setRouteOptionHovered({ commit }, routeOption) {
      commit('SET_ROUTE_OPTION_HOVERED', routeOption);
    },
    updatePendingStopName({ commit }, name) {
      commit('SET_PENDING_STOP_NAME', name);
    },
    cancelEditing({ commit }) {
      commit('SET_EDITING_STATE', false);
      commit('SET_EDITING_TRIP', 0);
      commit('SET_PENDING_STOP', {});
    },
    cancelEditingStops({ commit }) {
      commit('SET_EDITING_STATE', false);
      commit('SET_EDITING_TRIPSTOPS', 0);
    },
    editStop({ commit, state }, stopId) {
      commit('SET_EDITING_STATE', true);
      commit('SET_EDITING_TRIP', state.stop_trip_ids[stopId]);
      commit('SET_EDITING_TRIPSTOPS', state.stop_trip_ids[stopId]);
      commit('SET_EDITING_STOP', stopId);
    },
    addTripStops({ commit }, tripId) {
      commit('SET_TRIP_ID', tripId);
      commit('SET_ADDING_STOPS', true);
      commit('SET_EDITING_STATE', true);
    },
    cancelAddingTripStops({ commit }) {
      commit('SET_ADDING_STOPS', false);
      commit('SET_EDITING_STATE', false);
    },
    setStop({ dispatch, commit }, stop) {
      commit('SET_TRIP_ID', stop[0]);
      dispatch('getTripRouteDetails', stop[0]);
      return dispatch('getStopDetails', stop);
    },
    updateTripStops({ commit, dispatch, state }, trip) {
      // Create the new path
      const newPath = stitchPolylines(trip.ss.map((stop) => stop.route.ep));
      // This can be used to pass a fresh array of stops (ie, when re-sorting the stops)
      let newTrip = JSON.parse(
        JSON.stringify(
          Object.assign(state.trips[state.trip_positions[trip.tid]])
        )
      );

      newTrip = Object.assign(newTrip, {
        ss: trip.ss,
        stopPositions: getStopPositions(trip.ss),
        ep: newPath,
        coordinates: decodePolyline(newPath),
      });

      newTrip = getTripBoundsAndCoordinates(newTrip);
      commit('SET_TRIP', newTrip);
      commit('SET_UPDATED_TRIPS', [trip.tid]);
      dispatch('setRefreshRequired', true);
    },

    createNote({ commit, dispatch, state }, note) {
      const formData = new FormData();
      formData.append('note', note.rawNote);
      formData.append('is_private', note.isPrivate);
      formData.append('tripID', this.getters.selectedTrip.tid);
      formData.append('stopID', this.getters.selectedStop.sid);
      dispatch('setIsSaving', true);

      analyticsEvent('/map/', 'Created a note', '');

      return axios({
        method: 'post',
        url: '/ajax/BookmarkService.cfc?method=_insertNote',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.jwt}`,
        },
        withCredentials: true,
      }).then((result) => {
        dispatch('setIsSaving', false);
        if (result.status === 200 && result.data.success) {
          // Update the note, as it will have a HTML version
          const thisTrip = this.getters.selectedTrip;
          const { notes } =
            thisTrip.ss[thisTrip.stopPositions[this.getters.selectedStop.id]];
          notes.push(result.data.data);
          commit('SET_TRIP', thisTrip);
        } else {
          // TODO: Notify of failed save, revert the front-end
        }
      });
    },
    updateNote({ commit, dispatch, state }, note) {
      const formData = new FormData();
      formData.append('note', note.rawNote);
      formData.append('is_private', note.isPrivate);
      formData.append('noteID', note.id);
      dispatch('setIsSaving', true);

      return axios({
        method: 'post',
        url: '/ajax/BookmarkService.cfc?method=_updateNote',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.jwt}`,
        },
        withCredentials: true,
      }).then((result) => {
        dispatch('setIsSaving', false);
        if (result.status === 200 && result.data.success) {
          // Update the note, as it will have a HTML version
          const thisTrip = this.getters.selectedTrip;
          const { notes } =
            thisTrip.ss[thisTrip.stopPositions[this.getters.selectedStop.id]];
          for (let i = 0; i < notes.length; i++) {
            if (notes[i].noteID === note.id) {
              notes[i].note_raw = note.rawNote;
              notes[i].note = result.data.data.note;
              notes[i].is_private = note.isPrivate;
            }
          }
          commit('SET_TRIP', thisTrip);
        } else {
          // TODO: Notify of failed save, revert the front-end
        }
      });
    },
    deleteNote({ commit, dispatch, state }, noteID) {
      dispatch('setIsSaving', true);

      const formData = new FormData();
      formData.append('noteID', noteID);

      return axios({
        method: 'post',
        url: '/ajax/BookmarkService.cfc?method=_removeNote',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.jwt}`,
        },
        withCredentials: true,
      }).then((result) => {
        dispatch('setIsSaving', false);
        if (result.status === 200 && result.data.success) {
          // Update the note, as it will have a HTML version
          const { notes } =
            this.getters.selectedTrip.ss[
              this.getters.selectedTrip.stopPositions[
                this.getters.selectedStop.id
              ]
            ];
          for (let i = 0; i < notes.length; i++) {
            if (notes[i].noteID == noteID) {
              notes.splice(i, 1);
            }
          }
          commit('SET_TRIP', this.getters.selectedTrip);
        } else {
          // TODO: Notify of failed delete
        }
      });
    },
    updateTripTitle({ commit, state }, trip) {
      const newTrip = Object.assign(
        state.trips[state.trip_positions[trip.tid]],
        {
          tn: trip.tn,
        }
      );
      commit('SET_TRIP', newTrip);
      commit('SET_UPDATED_TRIPS', [trip.tid]);
    },
    updateTripDescription({ commit, state }, trip) {
      const newTrip = Object.assign(
        state.trips[state.trip_positions[trip.tid]],
        {
          td: trip.td,
        }
      );
      commit('SET_TRIP', newTrip);
      commit('SET_UPDATED_TRIPS', [trip.tid]);
    },
    updateTripColor({ commit, state }, trip) {
      const newTrip = Object.assign(
        state.trips[state.trip_positions[trip.tid]],
        {
          c: trip.c,
        }
      );
      // Update GeoJSON at the same time
      newTrip.linesGeoJSON = newTrip.linesGeoJSON.map((line) => {
        line.properties.c = trip.c;
        return line;
      });
      newTrip.pointsGeoJSON = newTrip.pointsGeoJSON.map((point) => {
        point.properties.c = trip.c;
        return point;
      });
      commit('SET_TRIP', newTrip);
      commit('SET_UPDATED_TRIPS', [trip.tid]);
    },
    updateTrip({ commit, state }, trip) {
      const newTrip = Object.assign(
        state.trips[state.trip_positions[trip.tid]],
        trip
      );
      commit('SET_TRIP', newTrip);
      commit('SET_UPDATED_TRIPS', [newTrip.tid]);
    },
    setCustomRoute({ state, commit }, route) {
      if (route.coordinates.length === 0) {
        commit('SET_CUSTOM_ROUTE', route);
      } else {
        const line = lineString(route.coordinates);
        const distance = length(line, { units: 'kilometers' });
        route.distance = distance;
        const newRoute = Object.assign(
          {},
          Object.assign(state.custom_route, route)
        );
        commit('SET_CUSTOM_ROUTE', newRoute);
      }
    },
    setCreatingCustomRoute({ commit }, creatingCustomRoute) {
      commit('SET_CREATING_CUSTOM_ROUTE', creatingCustomRoute);
    },
    setRoute({ commit, dispatch, state, getters }, newData) {
      let newTrip = getters.selectedTrip;
      let newStop = newTrip.ss[newTrip.stopPositions[newData.sid]];
      newStop.coordinates = decodePolyline(newData.ep);
      newStop = Object.assign(newStop, {
        tr: newData.mode,
        dp: newData.ep,
        rid: newData.route_id,
        distance: newData.distance,
        route: {
          ep: newData.ep,
          duration: newData.duration,
          distance: newData.distance,
          route_id: newData.route_id,
          route: newData.route,
          stopID: newData.sid,
          halfwayCoordinates: getHalfwayCoordinates(newStop.coordinates),
        },
      });
      newTrip = getTripBoundsAndCoordinates(newTrip);
      commit('SET_TRIP', newTrip);
      dispatch('updateCurrentTripRoute');
    },
    updateCurrentTripRoute({ commit, dispatch, state, getters }) {
      const routes = getters.routes.map((route) => route.ep);
      const newPath = stitchPolylines(routes);
      let newTrip = Object.assign(
        state.trips[state.trip_positions[state.trip_id]],
        {
          ep: newPath,
        }
      );
      newTrip = getTripBoundsAndCoordinates(newTrip);
      commit('SET_TRIP', newTrip);
      commit('SET_UPDATED_TRIPS', [newTrip.tid]);
      dispatch('setRefreshRequired', true);
    },
    selectRoute({ commit, state, getters, dispatch }, stopRoute) {
      const thisStop = getters.selectedTrip.ss[stopRoute.stop_index];
      const nextStop = getters.selectedTrip.ss[stopRoute.stop_index + 1];
      const routeKey = `${thisStop.lat},${thisStop.lng}>${nextStop.lat},${nextStop.lng}`;
      const thisRoute = state.route_options[routeKey][stopRoute.route_index];

      dispatch('setIsSaving', true);
      dispatch('setRouteStopIndex', -1);

      // After doing the setRoute, we should have a polyline for the trip
      const formData = new FormData();
      formData.append('tripID', getters.selectedTrip.tid);
      formData.append('stopID', thisStop.sid);
      formData.append('origin', `${thisStop.lng} ${thisStop.lat}`);
      formData.append('destination', `${nextStop.lng} ${nextStop.lat}`);
      formData.append('name', thisRoute.name);
      formData.append('ep', thisRoute.ep);
      formData.append(
        'mode',
        thisRoute.mode > 0 ? thisRoute.mode : thisStop.tr
      );
      formData.append('tripPath', getters.selectedTrip.ep);
      formData.append('segments', JSON.stringify(thisRoute.segments));

      return axios({
        method: 'post',
        url: '/ajax/MappingService.cfc?method=_setTripStopRoute',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.jwt}`,
        },
        withCredentials: true,
      }).then((result) => {
        dispatch('setIsSaving', false);
        if (result.status === 200 && result.data.success) {
          // We need to update the trip data with the new route ID
          dispatch('setRoute', {
            mode: thisRoute.mode > 0 ? thisRoute.mode : thisStop.tr,
            index: stopRoute.stop_index,
            sid: thisStop.sid,
            ep: thisRoute.ep,
            route: thisRoute.name,
            duration: thisRoute.duration,
            distance: thisRoute.distance,
            route_id: result.data.data.routeID,
          });
        } else {
          // TODO: Notify of failed save, revert the front-end
        }
      });
    },

    selectCustomRoute({ commit, state, getters, dispatch }, stopRoute) {
      const thisStop = getters.selectedTrip.ss[stopRoute.stop_index];
      const nextStop = getters.selectedTrip.ss[stopRoute.stop_index + 1];
      const thisRoute = state.custom_route;
      thisRoute.ep = encodePolyline(thisRoute.coordinates);
      thisRoute.tr = thisStop.tr;

      dispatch('setIsSaving', true);

      // After doing the setRoute, we should have a polyline for the trip
      const formData = new FormData();
      formData.append('tripID', getters.selectedTrip.tid);
      formData.append('stopID', thisStop.sid);
      formData.append('origin', `${thisStop.lng} ${thisStop.lat}`);
      formData.append('destination', `${nextStop.lng} ${nextStop.lat}`);
      formData.append('name', thisRoute.name);
      formData.append('ep', thisRoute.ep);
      formData.append('mode', thisStop.tr);
      formData.append('tripPath', getters.selectedTrip.ep);
      formData.append('segments', JSON.stringify([]));

      return axios({
        method: 'post',
        url: '/ajax/MappingService.cfc?method=_setTripStopRoute',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.jwt}`,
        },
        withCredentials: true,
      }).then((result) => {
        dispatch('setIsSaving', false);
        dispatch('setCustomRoute', { coordinates: [] });
        if (result.status === 200 && result.data.success) {
          // We need to update the trip data with the new route ID
          dispatch('setRoute', {
            mode: thisStop.tr,
            index: stopRoute.stop_index,
            sid: thisStop.sid,
            ep: thisRoute.ep,
            route: thisRoute.name,
            duration: thisRoute.duration,
            distance: thisRoute.distance,
            route_id: result.data.data.routeID,
          });
        } else {
          // TODO: Notify of failed save, revert the front-end
        }
        dispatch('setRouteStopIndex', -1);
      });
    },
    saveTripMeta({ commit, dispatch, state }, tripId) {
      analyticsEvent('/map/', 'Updated trip meta', '');

      const thisTrip = state.trips[state.trip_positions[tripId]];
      const formData = new FormData();
      formData.append('TripID', tripId);
      if (thisTrip.td != null) {
        formData.append('description', thisTrip.td);
      }
      if (thisTrip.tn != null) {
        formData.append('name', thisTrip.tn);
      }
      if (thisTrip.c !== null) {
        formData.append('color', thisTrip.c);
      }

      return axios({
        method: 'post',
        url: '/ajax/MappingService.cfc?method=_saveTripMeta',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.getters.jwt}`,
        },
        withCredentials: true,
      })
        .then((result) => {
          if (result.data.success) {
            commit('SET_UPDATED_TRIPS', []);
          } else {
            const message = result.data.messages
              .map((m) => m.message)
              .join(' ');
            throw message;
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    setTripPositions({ commit, state }) {
      // Get the new trip positions and update them
      const newTripPositions = {};
      state.trips.forEach((trip, index) => {
        newTripPositions[trip.tid] = index;
      });
      commit('ADD_TRIP_POSITIONS', newTripPositions);
    },
    deleteTrip({ commit, dispatch, state }, tripId) {
      analyticsEvent('/map/', 'Deleted a trip', '');
      // optimistic delete
      commit('SET_DELETED_TRIPS', [tripId]);
      commit('DELETE_TRIP', tripId);
      dispatch('setTripPositions');
      return axios
        .get('/ajax/MappingService.cfc', {
          params: {
            method: '_deleteTrip',
            tripid: this.getters.tripId,
          },
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${this.getters.jwt}`,
          },
        })
        .then((result) => {
          if (result.data.success) {
            commit('SET_TRIP_ID', 0);
          } else {
            throw new Error('Deleting failed');
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    getRouteBetween({ dispatch, state }, locations) {
      return new Promise((resolve, reject) => {
        const origin = locations[0];
        const destination = locations[1];
        const routeKey = `${origin.lat},${origin.lng}>${destination.lat},${destination.lng}`;
        // This will ensure we actually have the route
        dispatch('getRouteOptions', {
          from: [origin.lng, origin.lat],
          to: [destination.lng, destination.lat],
        })
          .then(() => {
            // First initialize a straight line between these two locations
            const thisRoute = {
              stopID: origin.sid,
              origin: `${origin.lng} ${origin.lat}`,
              destination: `${destination.lng} ${destination.lat}`,
              mode: origin.tr !== null ? origin.tr : 0,
              name: '',
              segments: [],
              ep:
                origin.tr <= 1 // If it's a flight or unknown, make it a curve
                  ? state.route_options[routeKey][0].ep // curve
                  : state.route_options[routeKey][1].ep, // straight line
              name:
                origin.tr <= 1 // If it's a flight or unknown, make it a curve
                  ? state.route_options[routeKey][0].name // curve
                  : state.route_options[routeKey][1].name, // straight line
              duration: state.route_options[routeKey][0].duration,
              distance: state.route_options[routeKey][0].distance,
            };

            // Set to a matching route if possible
            let foundRoute = false;
            state.route_options[routeKey].some((option) => {
              if (option.mode === origin.tr) {
                thisRoute.ep = option.ep;
                thisRoute.name = option.name;
                thisRoute.duration = option.duration;
                thisRoute.distance = option.distance;
                if (option.segments != null) {
                  thisRoute.segments = option.segments;
                }
                foundRoute = true;
                return true;
              }
            });

            if (!foundRoute && [6, 7, 8, 9].indexOf(origin.tr) > -1) {
              // If we didn't find a route and this route can be on the roads, let's switch to that.
              // bus, foot, bicycle, motorbike, animal all map to driving route
              state.route_options[routeKey].some((option) => {
                if (option.mode === 4) {
                  // 4 == car
                  thisRoute.ep = option.ep;
                  thisRoute.name = option.name;
                  thisRoute.duration = option.duration;
                  thisRoute.distance = option.distance;
                  if (option.segments != null) {
                    thisRoute.segments = option.segments;
                  }
                  return true;
                }
              });
            }

            resolve(thisRoute);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    async getMultipleRoutesBetween({ dispatch }, routesToGet) {
      return new Promise((resolve, reject) => {
        const promises = [];
        if (routesToGet.length) {
          routesToGet.forEach((locations) => {
            promises.push(dispatch('getRouteBetween', locations));
          });

          Promise.all(promises)
            .then((routes) => {
              resolve(routes);
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          resolve([]);
        }
      });
    },
    addStop({ commit, dispatch, state }, stop) {
      let routesToSet = [];
      let previousStopIndex = -1;
      let outboundRoute = {};
      let newTrip = JSON.parse(
        JSON.stringify(state.trips[state.trip_positions[stop.tid]])
      );
      delete newTrip.stops;
      dispatch('setIsSaving', true);
      const routesToGet = [];

      if (stop.createAfterThisTripStop > 0) {
        // Start with the previous stop's route
        if (stop.arriving_by != null) {
          previousStopIndex =
            newTrip.stopPositions[stop.createAfterThisTripStop];
          const previousStop = newTrip.ss[previousStopIndex];
          previousStop.tr = stop.arriving_by;
          routesToGet.push([previousStop, stop]);
        }
      }

      const nextStop = newTrip.ss[previousStopIndex + 1];

      // Outbound route as well if we're inserting the stop.
      if (nextStop != null) {
        routesToGet.push([stop, nextStop]);
      }

      // Go get those routes and come back when done.
      dispatch('getMultipleRoutesBetween', routesToGet).then((routes) => {
        routesToSet = routes;

        routesToSet.forEach((route) => {
          //console.log(route);
          if (route.stopID !== 'new' && route.stopID != null) {
            newTrip.ss[newTrip.stopPositions[route.stopID]] = Object.assign(
              newTrip.ss[newTrip.stopPositions[route.stopID]],
              {
                tr: stop.tr,
                dp: route.ep,
                route,
              }
            );
          } else {
            // it's our new route
            route.stopID = 'new'; // make sure this is here.. we're counting on it.
            outboundRoute = route;
          }
        });

        newTrip.ss.splice(previousStopIndex + 1, 0, {
          ad: stop.ad,
          cc: stop.cc,
          dd: stop.dd,
          l: stop.l,
          lat: stop.lat,
          lng: stop.lng,
          rid: 0,
          route: outboundRoute,
          dp: outboundRoute.ep,
          sid: 0,
          tr: stop.tr,
        });

        const pathsToStitch = newTrip.ss.map((thisStop) => {
          if (thisStop.route.ep != null) {
            return thisStop.route.ep;
          }
        });

        newTrip.ep = stitchPolylines(pathsToStitch);

        // We should have access to a new trip path now.
        const formData = new FormData();
        formData.append('tripID', stop.tid);
        formData.append('latitude', stop.lat);
        formData.append('longitude', stop.lng);
        formData.append('position', stop.position);
        formData.append('countryCode', stop.cc);
        formData.append('locationname', stop.l);
        formData.append('arrival_date', stop.ad);
        formData.append('departure_date', stop.dd);
        formData.append('transportid', stop.tr);
        formData.append(
          'createAfterThisTripStop',
          stop.createAfterThisTripStop
        );
        formData.append('routesToSet', JSON.stringify(routesToSet));
        formData.append('tripPath', newTrip.ep);

        return axios({
          method: 'post',
          url: '/ajax/MappingService.cfc?method=_createTripStopWithRoutes',
          data: formData,
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.getters.jwt}`,
          },
        }).then((result) => {
          dispatch('setIsSaving', false);
          if (result.data.success) {
            // add this stop in at the right spot in the trip
            // Add the stop ID into our updated trip
            newTrip.ss[previousStopIndex + 1].sid = Number(
              result.data.data.stopID
            );
            if (previousStopIndex > -1) {
              newTrip.ss[previousStopIndex].rid = Number(
                result.data.data.routeID
              );
              newTrip.ss[previousStopIndex].route.route_id = Number(
                result.data.data.routeID
              );
            }
            newTrip = getTripBoundsAndCoordinates(newTrip);
            dispatch('updateTripStops', newTrip);
            dispatch('updateTrip', newTrip);
            dispatch('updateCurrentTripRoute');
            commit('SET_PENDING_STOP', {});
            commit('SET_HAS_PENDING_STOP', false);
            commit('SET_ROUTE_STOP_INDEX', -1);
          } else {
            // NOTIFY of failed save
          }
        });
      });
    },
    updateStop({ commit, dispatch, state }, stop) {
      const routesToRemove = [];
      let previousStopIndex = -1;
      const newTrip = JSON.parse(
        JSON.stringify(state.trips[state.trip_positions[stop.tid]])
      );
      const stopIndex = newTrip.stopPositions[stop.sid];
      let newInboundRouteRequired = false;
      let newOutboundRouteRequired = false;
      let movingStop = false;
      const currentNextStop = newTrip.ss[stopIndex + 1];
      const currentPreviousStop =
        newTrip.ss[newTrip.stopPositions[stop.sid] - 1];
      let newNextStop = currentNextStop;
      let newPreviousStop = {};
      let removeOutboundRoute = false;
      if (stop.createAfterThisTripStop > 0) {
        newPreviousStop =
          newTrip.ss[newTrip.stopPositions[stop.createAfterThisTripStop]];
      }

      // Find out if new routing is required
      if (
        (currentPreviousStop == null && stop.createAfterThisTripStop > 0) ||
        (currentPreviousStop != null &&
          currentPreviousStop.sid !== stop.createAfterThisTripStop)
      ) {
        // The previous stop ID has changed. We're moving
        // We'll need to update lots of routes
        newInboundRouteRequired = true;
        movingStop = true;
        if (stop.createAfterThisTripStop > 0) {
          newNextStop =
            newTrip.ss[newTrip.stopPositions[stop.createAfterThisTripStop] + 1];
        } else {
          newNextStop = newTrip.ss[0];
        }
        if (newNextStop != null) {
          newOutboundRouteRequired = true;
        } else {
          removeOutboundRoute = true;
        }
      } else if (
        currentPreviousStop != null &&
        currentPreviousStop.tr !== stop.arriving_by
      ) {
        // The preivous stop ID hasn't changed, but the incoming arrival method has.
        // Just update the inbound route
        newInboundRouteRequired = true;
      }

      delete newTrip.stops;

      dispatch('setIsSaving', true);

      const routesToGet = [];

      if (newInboundRouteRequired) {
        // Start with the previous stop's route
        if (newPreviousStop.sid != null) {
          newPreviousStop.tr = stop.arriving_by;
          routesToGet.push([newPreviousStop, stop]);
        }
      }

      // Outbound route as well if we're inserting the stop.
      if (newOutboundRouteRequired) {
        routesToGet.push([stop, newNextStop]);
      }

      // If we're moving the stop, then we also need to update the outbound route of
      // whatever stop used to be before this one
      if (
        movingStop &&
        currentPreviousStop != null &&
        currentNextStop != null
      ) {
        routesToGet.push([currentPreviousStop, currentNextStop]);
      } else if (
        movingStop &&
        currentPreviousStop != null &&
        currentNextStop == null
      ) {
        // We're moving from the last stop. We'll need to remove the route from the current previous stop
        routesToRemove.push({
          stopID: currentPreviousStop.sid,
          mode: currentPreviousStop.tr,
          ep: '',
        });
      }
      if (removeOutboundRoute) {
        routesToRemove.push({
          stopID: stop.sid,
          mode: stop.tr,
          ep: '',
        });
      }

      // Go get those routes and come back when done.
      dispatch('getMultipleRoutesBetween', routesToGet).then((routes) => {
        const routesToSet = routes;

        routesToSet.forEach((route) => {
          newTrip.ss[newTrip.stopPositions[route.stopID]] = Object.assign(
            newTrip.ss[newTrip.stopPositions[route.stopID]],
            {
              dp: route.ep,
              route,
            }
          );
        });

        // Handle any removed ones
        routesToRemove.forEach((route) => {
          newTrip.ss[newTrip.stopPositions[route.stopID]] = Object.assign(
            newTrip.ss[newTrip.stopPositions[route.stopID]],
            {
              dp: '',
              route: {
                distance: 0,
                duration: 0,
                ep: '',
                route: '',
                route_id: 0,
                mode: 0,
                stopID: route.stopID,
              },
            }
          );
          routesToSet.push(route);
        });

        // Now move our stop into the right spot in the array
        if (movingStop) {
          const ourStop = newTrip.ss[newTrip.stopPositions[stop.sid]];
          newTrip.ss.splice(newTrip.stopPositions[stop.sid], 1);
          // previous stop index might have moved, so do a loop to make sure we put this in the right spot
          if (stop.createAfterThisTripStop === 0) {
            stop.position = 0;
          } else {
            for (let i = 0; i < newTrip.ss.length; i++) {
              if (newTrip.ss[i].sid === stop.createAfterThisTripStop) {
                previousStopIndex = i;
                ourStop.position = stop.position = i + 1;
              }
            }
          }
          newTrip.ss.splice(previousStopIndex + 1, 0, ourStop);
        }

        const pathsToStitch = newTrip.ss.map((thisStop) => {
          if (thisStop.dp != null) {
            return thisStop.dp;
          }
        });
        newTrip.ep = stitchPolylines(pathsToStitch);
        dispatch('updateTripStops', newTrip);

        // We should have access to a new trip path now.
        const formData = new FormData();
        formData.append('tripID', stop.tid);
        formData.append('stopID', stop.sid);
        formData.append('latitude', stop.lat);
        formData.append('longitude', stop.lng);
        formData.append('position', stop.position);
        formData.append('countryCode', stop.cc);
        formData.append('locationname', stop.l);
        formData.append('arrival_date', stop.ad);
        formData.append('departure_date', stop.dd);
        formData.append('transportid', stop.tr);
        formData.append(
          'createAfterThisTripStop',
          stop.createAfterThisTripStop
        );
        formData.append('routesToSet', JSON.stringify(routesToSet));
        formData.append('routesToRemove', JSON.stringify(routesToRemove));
        formData.append('tripPath', newTrip.ep);

        return axios({
          method: 'post',
          url: '/ajax/MappingService.cfc?method=_updateTripStopWithRoutes',
          data: formData,
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.getters.jwt}`,
          },
        }).then((result) => {
          dispatch('setIsSaving', false);
          if (result.data.success) {
            // add this stop in at the right spot in the trip
            // Add the stop ID into our updated trip
            dispatch('updateTripStops', newTrip);
          } else {
            // NOTIFY of failed save
          }
        });
      });
    },
    deleteStop({ commit, dispatch, state }, stopId) {
      const self = this;
      dispatch('setIsSaving', true);
      // If for some reason the stopID is undefined (perhaps they reloaded the edit page?),
      // go to the main trip view and skip all the logic below
      if (stopId == null) {
        dispatch('setIsSaving', false);
        commit('SET_STOP_ID', 0);
      } else {
        const stopPosition = this.getters.selectedTrip.stopPositions[stopId];
        const previousStop = this.getters.selectedTrip.ss[stopPosition - 1];
        const nextStop = this.getters.selectedTrip.ss[stopPosition + 1];
        const routesToGet = [];
        const routesToRemove = [];
        let newTrip = JSON.parse(JSON.stringify(this.getters.selectedTrip));

        // If there is a previous stop and a next stop,
        // we'll need to get new routes and update the previous stop with the best route
        if (previousStop != null && nextStop != null) {
          routesToGet.push([previousStop, nextStop]);
        } else if (previousStop != null && nextStop == null) {
          routesToRemove.push({
            stopID: previousStop.sid,
            mode: previousStop.tr,
            ep: '',
          });
        }

        dispatch('getMultipleRoutesBetween', routesToGet).then((routes) => {
          const routesToSet = routes;

          routesToSet.forEach((route) => {
            newTrip.ss[newTrip.stopPositions[route.stopID]] = Object.assign(
              newTrip.ss[newTrip.stopPositions[route.stopID]],
              {
                dp: route.ep,
                route,
              }
            );
          });

          // Handle any removed ones (only really happens if it was a stop at the end of the trip being removed)
          routesToRemove.forEach((route) => {
            newTrip.ss[newTrip.stopPositions[route.stopID]] = Object.assign(
              newTrip.ss[newTrip.stopPositions[route.stopID]],
              {
                dp: '',
                route: {
                  distance: 0,
                  duration: 0,
                  ep: '',
                  route: '',
                  route_id: 0,
                  stopID: route.stopID,
                },
              }
            );
            routesToSet.push(route);
          });

          // Now remove the current stop from our trip
          newTrip.ss.splice(stopPosition, 1);

          const pathsToStitch = newTrip.ss.map((thisStop) => {
            if (thisStop.route.ep != null) {
              return thisStop.route.ep;
            }
          });
          newTrip.ep = stitchPolylines(pathsToStitch);

          newTrip.stopPositions = getStopPositions(newTrip.ss);
          newTrip.coordinates = decodePolyline(newTrip.ep);
          newTrip = getTripBoundsAndCoordinates(newTrip);

          commit('SET_TRIP', newTrip);
          commit('SET_UPDATED_TRIPS', [newTrip.tid]);
          commit('SET_HOVERED_STOP_ID', 0);

          // We should have access to a new trip path now.
          const formData = new FormData();
          formData.append('tripID', this.getters.tripId);
          formData.append('stopID', stopId);
          formData.append('routesToSet', JSON.stringify(routesToSet));
          formData.append('tripPath', newTrip.ep);

          // Now delete the stop from our database

          return axios({
            method: 'post',
            url: '/ajax/MappingService.cfc?method=_deleteTripStopWithRoutes',
            data: formData,
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${this.getters.jwt}`,
            },
          })
            .then((result) => {
              dispatch('setIsSaving', false);
              if (result.data.success) {
                commit('SET_STOP_ID', 0);
                commit('SET_UPDATED_TRIPS', [this.getters.tripId]);
                dispatch('updateCurrentTripRoute');
              } else {
                // NOTIFY of failed delete
              }
            })
            .catch((error) => {
              throw error;
            });
        });
      }
    },

    getIsSupporter({ state, commit, dispatch }) {
      return axios
        .get(
          `/ajax/UserService.cfc?method=_isSupporter&userID=${state.trip_user_id}`
        )
        .then((result) => {
          if (result.status == 200) {
            dispatch('setIsSupporter', result.data);
          } else {
            // Return a message saying the fetch failed
          }
          return result.data;
        });
    },

    getTripUserId({ state, commit }) {
      return axios
        .get(
          `/ajax/MappingService.cfc?method=_getTripUserId&tripID=${state.trip_id}`
        )
        .then((result) => {
          if (result.status == 200 && result.data.success) {
            commit('SET_TRIP_USER_ID', result.data.data);
          } else {
            // Return a message saying the fetch failed
          }
          return result.data;
        });
    },

    getTripJson({ state, commit, dispatch }) {
      if (!state.loaded_trips) {
        dispatch('setLoadingTrips', true);
        return axios
          .get(
            `/ajax/MappingService.cfc?method=_getUserMapJSON&userid=${this.state.trip_user_id}&tripid=${this.state.trip_id}`,
            {
              withCredentials: true,
            }
          )
          .then((result) => {
            if (result.status === 200) {
              const { trips } = result.data.data;
              const trip_positions = {};
              let stop_trip_ids = {};
              let x = 0;
              dispatch('setIsSupporter', !!result.data.data.isSupporter);
              dispatch('setTripUserId', result.data.data.userId);
              dispatch('setTripUsername', result.data.data.username);
              trips.forEach((trip) => {
                trip = getTripBoundsAndCoordinates(trip);
                trip_positions[trip.tid] = parseInt(x, 10);
                x++;
                stop_trip_ids = {};
                trip.ss.forEach((stop) => {
                  stop_trip_ids[stop.sid] = trip.tid;
                });
                commit('SET_STOP_TRIP_ID', stop_trip_ids);
              });

              commit('ADD_TRIPS', trips);
              commit('ADD_TRIP_POSITIONS', trip_positions);
              commit('SET_LOADED_TRIPS');
            } else {
              // Return a message saying the fetch failed
            }
            dispatch('setLoadingTrips', false);
          });
      }
    },
    getRouteOptions({ state, dispatch }, locations) {
      // r2r will want these coords flipped.
      const oPos = [locations.from[1], locations.from[0]];
      const dPos = [locations.to[1], locations.to[0]];

      const routeKey = `${oPos.join(',')}>${dPos.join(',')}`;

      const curve = getCurvedLine(locations.from, locations.to);
      const distance = distanceBetween(locations.from, locations.to);
      const baseOptions = [
        {
          distance,
          distance_string: '',
          duration: '',
          name: 'Curved Line',
          optionNumber: 0,
          ep: encodePolyline(curve),
          segments: [],
          mode: 0,
        },
        {
          distance,
          distance_string: '',
          duration: '',
          name: 'Straight Line',
          optionNumber: -1,
          segments: [],
          ep: encodePolyline([locations.from, locations.to]),
          mode: 0,
        },
      ];

      return new Promise((resolve, reject) => {
        if (!state.route_options.hasOwnProperty(routeKey)) {
          dispatch('setLoadingRoutes', [routeKey, true]);

          axios
            .request({
              url: '/ajax/MappingService.cfc',
              params: {
                method: 'getRouteOptions2',
                oPos: oPos.join(','),
                dPos: dPos.join(','),
              },
              timeout: 4000,
            })
            .then((result) => {
              if (result.status == 200) {
                const routeOptions = [...baseOptions, ...result.data.data];
                dispatch('setRouteOptions', {
                  key: routeKey,
                  options: routeOptions,
                });
                resolve();
              }
            })
            .catch((err) => {
              dispatch('setRouteOptions', {
                key: routeKey,
                options: baseOptions,
              });
              reject(err);
            })
            .finally(() => {
              dispatch('setLoadingRoutes', [routeKey, false]);
            });
        } else {
          resolve();
          // It should already exist, nothing to do here
        }
      });
    },
    getTripRouteDetails({ commit, state, dispatch }, tripId) {
      // Only do this if we haven't already got the routes stored for this trip
      if (state.tripRoutesLoaded[tripId] == null) {
        axios
          .get('/ajax/MappingService.cfc', {
            params: {
              method: '_getTripRouteDetails',
              returnFormat: 'json',
              tripid: tripId,
            },
          })
          .then((result) => {
            if (result.status == 200 && result.data.success) {
              const thisTrip = state.trips[state.trip_positions[tripId]];
              result.data.data.ss.forEach((stop) => {
                const thisStop = thisTrip.ss[thisTrip.stopPositions[stop.sid]];
                thisStop.route = stop;
                let lineDistance = 0;
                if (thisStop.coordinates.length > 1) {
                  const line = lineString(thisStop.coordinates);
                  lineDistance =
                    Math.round(length(line, { units: 'kilometers' }) * 100) /
                    100;
                }
                thisStop.distance = thisStop.route.distance || lineDistance;
                thisStop.route.halfwayCoordinates = getHalfwayCoordinates(
                  thisStop.coordinates
                );
                thisStop.route.stopID = thisStop.route.sid;
                thisStop.route.tr = thisStop.tr;
                thisStop.route.route_id = thisStop.rid;
              });
              commit('SET_TRIP_ROUTES_LOADED', tripId);
              commit('SET_TRIP', thisTrip);
            } else {
              // return an error..
            }
          });
      }
    },
    getStopDetails({ commit, state }, details) {
      const thisTrip = this.state.trips[this.state.trip_positions[details[0]]];
      const thisStop = thisTrip.ss[thisTrip.stopPositions[details[1]]];
      return axios
        .get('/ajax/redirect.cfm', {
          params: {
            component: 'mapping',
            method: 'getStopDetails',
            returnFormat: 'json',
            userid: this.state.trip_user_id,
            arrive_date: thisStop.ad != null ? thisStop.ad : '0000-00-00',
            depart_date: thisStop.dd != null ? thisStop.dd : '0000-00-00',
            session: false,
            lat: thisStop.lat,
            lng: thisStop.lng,
            tripid: details[0],
            stopid: thisStop.sid,
            include_private: !this.state.is_embedded,
          },
          withCredentials: true,
        })
        .then((result) => {
          if (result.status == 200) {
            const newStopData = {};
            newStopData[result.data.data.id] = Object.assign(
              thisStop,
              result.data.data
            );
            newStopData[result.data.data.id].position =
              thisTrip.stopPositions[details[1]];
            if (this.state.is_embedded) {
              newStopData[result.data.data.id].notes = newStopData[
                result.data.data.id
              ].notes.map((thisNote) => {
                thisNote.note = targetLinksToNewTab(thisNote.note);
                return thisNote;
              });
            }
            commit('ADD_STOP_DATA', newStopData);
            commit('SET_STOP_ID', result.data.data.id);
          } else {
            // Return a message saying the fetch failed
          }
        });
    },
    getAccommodationNearby({ commit, state }, stop) {
      commit('SET_NEARBY_PROPERTIES', []);
      commit('SET_LOADING_NEARBY_PROPERTIES', true);

      return axios
        .get('/ajax/AccommodationService.cfc?method=_getAccommodationNearby', {
          params: {
            lat: stop.lat,
            lng: stop.lng,
            size: 4,
          },
        })
        .then((result) => {
          if (result.status === 200) {
            commit('SET_NEARBY_PROPERTIES', result.data);
          }
        })
        .finally(() => {
          commit('SET_LOADING_NEARBY_PROPERTIES', false);
        });
    },
    createNewTrip({ dispatch, commit, state }, trip) {
      dispatch('setIsSaving', true);
      const tripCount = state.trips.length;
      analyticsEvent('/map/', 'Created trip', '');
      return axios
        .get('/ajax/MappingService.cfc', {
          params: {
            method: '_createTrip',
            tripname: trip.tn,
            description: trip.td,
            color: trip.c,
          },
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${this.getters.jwt}`,
          },
        })
        .then((result) => {
          dispatch('setIsSaving', false);
          if (result.data.success) {
            const thisTrip = Object.assign(trip, {
              tid: result.data.data,
              ss: [],
              coordinates: [],
              ep: '',
              maxBounds: {
                maxLat: 80,
                maxLng: 179,
                minLat: -80,
                minLng: -179,
              },
              dis: 0,
            });
            commit('ADD_TRIP_TO_TRIPS', thisTrip);
            const newTripPositions = state.trip_positions;
            newTripPositions[thisTrip.tid] = tripCount; // set position to end of trip
            commit('ADD_TRIP_POSITIONS', newTripPositions);
            return thisTrip;
          }
          // NOTIFY of failed save
        });
    },
  },
  getters: {
    tripId: (state) => state.trip_id,
    stopId: (state) => state.stop_id,
    userId: (state) => state.user_id,
    tripUserId: (state) => state.trip_user_id,
    tripUsername: (state) => state.trip_username,
    isLoggedIn: (state) => state.user_id > 0,
    isViewingOwnTrip: (state) =>
      state.trip_user_id === state.user_id && state.user_id > 0,
    safeTripUsername: (state) => encodeURI(state.trip_username),
    hoveredTripId: (state) => state.hovered_trip_id,
    hoveredStopId: (state) => state.hovered_stop_id,
    tripPositions: (state) => state.trip_positions,
    errorMessage: (state) => state.error_message,
    distancePreference: (state) => state.distance_preference,
    currentLocation: (state) => state.current_location,
    lineWidth: (state) => state.line_width,
    lineStyle: (state) => state.line_style,
    showAllLines: (state) => state.show_all_lines,
    refreshRequired: (state) => state.refresh_required,
    selectedTrip: (state) => {
      let thisTrip = state.trips[state.trip_positions[state.trip_id]];
      if (thisTrip == null) {
        thisTrip = {
          c: '#eeeeee',
          tn: '',
          ss: [],
          td: '',
          tid: 0,
          dis: 0,
          coordinates: [],
          stopPositions: {},
          maxBounds: {},
        };
      }
      return thisTrip;
    },
    selectedStop: (state) => {
      let thisStop = state.stop_data[state.stop_id];
      if (thisStop == null) {
        thisStop = {
          ad: '0000-00-00',
          dd: '0000-00-00',
          blogs: {
            blogs: [],
          },
          notes: [],
          photos: {
            photos: [],
          },
          wiki: {},
          cc: '',
          id: 0,
          l: '',
          lat: 0,
          lng: 0,
          position: 0,
        };
      }
      return thisStop;
    },
    routeOptions: (state, getters) => {
      if (
        getters.selectedTrip.ss.length > state.route_stop_index + 1 &&
        state.route_stop_index !== -1
      ) {
        const from = getters.selectedTrip.ss[state.route_stop_index];
        const to = getters.selectedTrip.ss[state.route_stop_index + 1];
        const routeKey = `${from.lat},${from.lng}>${to.lat},${to.lng}`;
        if (state.route_options.hasOwnProperty(routeKey)) {
          return state.route_options[routeKey];
        }
        return [];
      }
      return [];
    },
    routes: (state, getters) => {
      const routes = [];
      getters.selectedTrip.ss.forEach((stop) => {
        if (stop.route != null) {
          routes.push(stop.route);
        }
      });
      return routes;
    },
    trips: (state) => state.trips,
    photo: (state) => state.photo,
    addingNewTrip: (state) => state.adding_new_trip,
    editTripId: (state) => state.edit_trip_id,
    editStopId: (state) => state.edit_stop_id,
    editing: (state) => state.editing,
    addingStops: (state) => state.adding_stops,
    pendingStop: (state) => state.pending_stop,
    updatedTrips: (state) => state.updated_trips,
    deletedTrips: (state) => state.deleted_trips,
    addStopsToTripId: (state) => state.add_stops_to_trip_id,
    geocoderInited: (state) => state.geocoder_inited,
    tripWithStopsBeingEdited: (state) =>
      state.trips[state.trip_positions[state.add_stops_to_trip_id]],
    loadingTrips: (state) => state.loading_trips,
    editingRoutes: (state) => state.editing_routes,
    routeStopIndex: (state) => state.route_stop_index,
    isLoadingRoutes: (state) => {
      let isLoading = false;
      Object.values(state.loading_routes).some((status) => {
        if (status) {
          isLoading = true;
          return true;
        }
      });
      return isLoading;
    },
    isSaving: (state) => state.is_saving,
    routeOptionHovered: (state) => state.route_option_hovered,
    hasPendingStop: (state) => state.has_pending_stop,
    mapStartingZoom: (state) => state.map_starting_zoom,
    mapStartingCenter: (state) => state.map_starting_center,
    tiles: (state) => state.tiles,
    isSupporter: (state) => state.is_supporter,
    imageRequested: (state) => state.image_requested,
    includeGuide: (state) => state.include_guide,
    includeAllTripsLink: (state) => state.include_all_trips_link,
    jwt() {
      return Vue.cookie.get('JWT');
    },
    stopTips: (state) => {
      const tips = state.tips.tips_by_stop[state.stop_id];
      return tips || [];
    },
    nearbyProperties: (state) => state.nearby_properties,
    loadingNearbyProperties: (state) => state.loading_nearby_properties,
    lineTypesToShow: (state) => state.line_types_to_show,
    customRoute: (state) => state.custom_route,
    customRouteCoordinates: (state) => state.custom_route.coordinates,
    creatingCustomRoute: (state) => state.creating_custom_route,
  },
});

function getTripBoundsAndCoordinates(trip) {
  trip.maxBounds = {
    maxLng: -179,
    minLng: 179,
    maxLat: -90,
    minLat: 90,
  };
  const polylineArray = trip.ss.map((s) => s.dp);
  trip.ep = stitchPolylines(polylineArray);
  trip.coordinates = decodePolyline(trip.ep);
  trip.pointsGeoJSON = [];
  trip.linesGeoJSON = [];

  let lastLng;
  let adjust = 0;
  let adjustedCoordinates = [];

  /* Achieving several things in this loop:
    1. Adjust for dateline mess in Leaflet
    2. Get maxbounds for the trip
    3. Generate a GeoJSON representation of our trip's stops
    4. Adding a distance for each stop. 
  */

  trip.ss.forEach((stop, index) => {
    if (stop.dp == null) {
      stop.dp = ''; // Set it to an empty string
    }
    stop.coordinates = decodePolyline(stop.dp);
    if (stop.coordinates.length === 0 && trip.ss[index + 1] != null) {
      // This should have a polyline. It's probably an older trip.
      const nextStop = trip.ss[index + 1];
      if (stop.tr == 0 || stop.tr == 1) {
        // If it is unknown or a plane trip, make it a curved line.
        stop.coordinates = getCurvedLine(
          [stop.lng, stop.lat],
          [nextStop.lng, nextStop.lat]
        );
      } else {
        stop.coordinates = [
          [stop.lng, stop.lat],
          [nextStop.lng, nextStop.lat],
        ];
      }
    }
    adjustedCoordinates = [];
    stop.coordinates.forEach((coord) => {
      // Round them a bit
      const thisCoord = [
        Math.round(coord[0] * 10000) / 10000,
        Math.round(coord[1] * 10000) / 10000,
      ];
      // Adjust for the dateline mess in leaflet
      let lng = thisCoord[0];
      if (lastLng != null) {
        if (lng - lastLng > 180) {
          adjust -= 360; // Update the adjustment to be made
        } else if (lng - lastLng < -180) {
          adjust += 360; // Update the adjustment to be made
        }
        lng += adjust;
      }
      lastLng = thisCoord[0];
      adjustedCoordinates.push([lng, thisCoord[1]]);

      // Set max bounds
      if (lng > trip.maxBounds.maxLng) {
        trip.maxBounds.maxLng = lng;
      }
      if (lng < trip.maxBounds.minLng) {
        trip.maxBounds.minLng = lng;
      }
      if (thisCoord[1] > trip.maxBounds.maxLat) {
        trip.maxBounds.maxLat = thisCoord[1];
      }
      if (thisCoord[1] < trip.maxBounds.minLat) {
        trip.maxBounds.minLat = thisCoord[1];
      }
    });
    stop.coordinates = adjustedCoordinates;

    /* Generate a GeoJSON representation of the trip's lines */
    const future = new Date(stop.dd) > new Date() ? true : false;

    trip.linesGeoJSON.push({
      geometry: {
        type: 'LineString',
        coordinates: stop.coordinates,
      },
      type: 'Feature',
      properties: {
        i: index,
        rid: stop.rid, // routeID
        c: trip.c, // color
        tid: trip.tid, // tripid
        tr: stop.tr, // transport type
        future: future,
        dashArrayStyle: future ? [2, 3] : [1],
      },
    });
  });

  trip.stopPositions = {};

  trip.ss = trip.ss.map((stop, index) => {
    trip.stopPositions[stop.sid] = index;
    // Store the points GeoJSON here so we don't need to recalculate in the map
    trip.pointsGeoJSON.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [stop.lng, stop.lat],
      },
      properties: {
        c: trip.c,
        l: stop.l,
        sid: stop.sid,
        tid: trip.tid,
        future: new Date(stop.ad) > new Date(),
      },
    });
    if (stop.ad === '0000-00-00' || stop.ad == null) {
      stop.ad = '';
    }
    if (stop.dd === '0000-00-00' || stop.dd == null) {
      stop.dd = '';
    }
    if (stop.route == null) {
      return Object.assign(stop, {
        route: {},
      });
    } else if (stop.route.halfwayCoordinates == null && stop.route.ep) {
      const decodedPolyline = decodePolyline(stop.route.ep);
      stop.route.halfwayCoordinates = getHalfwayCoordinates(decodedPolyline);
    }
    return stop;
  });
  return trip;
}

function getStopPositions(stops) {
  const positions = {};
  stops.forEach((stop, index) => {
    positions[stop.sid] = index;
  });
  return positions;
}
