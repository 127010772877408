import polyline from '@mapbox/polyline';
import along from '@turf/along';
import center from '@turf/center';
import { lineString, points } from '@turf/helpers';
import length from '@turf/length';
import { getCoord } from '@turf/invariant';

/*
 * Polylines are encoded in lat/lng order. We use the more accepted mapping standard of lng/lat
 * These functions take care of the tedious job of flipping those coordinates etc before and after encoding them.
 */

// Returns coordinates in [lng, lat ] order
export function decodePolyline(encodedPolyline) {
  if (!encodedPolyline) return [];
  const decodedPolyline = polyline.decode(encodedPolyline);
  decodedPolyline.forEach((coords) => coords.reverse());
  return decodedPolyline;
}

// Expects coordinates in [lng, lat] order
export function encodePolyline(coordinates) {
  if (coordinates == null || coordinates.length === 0) {
    return '';
  }
  coordinates.forEach((coords) => coords.reverse());
  const encodedPolyline = polyline.encode(coordinates);
  return encodedPolyline;
}

// Expects an array of encoded polylines
export function stitchPolylines(polylines) {
  // Expects an array of polylines
  let allCoordinates = [];
  // Take it easy if no stitching is required.
  if (
    polylines.length === 0 ||
    (polylines.length === 1 && typeof polylines[0] == null)
  ) {
    return '';
  } else if (polylines.length === 1) {
    return polylines[0];
  }
  polylines.forEach((thisPolyline) => {
    if (thisPolyline != null) {
      allCoordinates = allCoordinates.concat(polyline.decode(thisPolyline));
    }
  });
  return polyline.encode(allCoordinates);
}

// Expects coordinates in [lng, lat] order
export function getPolylineBounds(coordinates) {
  // This expects it in order lng / lat
  const bounds = {
    maxLng: -179,
    minLng: 179,
    maxLat: -90,
    minLat: 90,
  };

  if (coordinates == null || coordinates.length === 0) {
    return bounds;
  }

  coordinates.forEach((coord) => {
    if (coord[0] > bounds.maxLng) {
      bounds.maxLng = coord[0];
    }
    if (coord[0] < bounds.minLng) {
      bounds.minLng = coord[0];
    }
    if (coord[1] > bounds.maxLat) {
      bounds.maxLat = coord[1];
    }
    if (coord[1] < bounds.minLat) {
      bounds.minLat = coord[1];
    }
  });

  return bounds;
}

// Expects coordinates in [lng, lat] order
export function getHalfwayCoordinates(coordinates) {
  if (coordinates.length === 0) return [];
  if (coordinates.length === 1) return coordinates[0];

  if (coordinates.length === 2) {
    const p = points(coordinates);
    const centerPoint = center(p);
    return centerPoint.geometry.coordinates;
  }
  const line = lineString(coordinates);
  const lineDistance =
    Math.round(length(line, { units: 'kilometers' }) * 100) / 100;
  const halfway = along(line, lineDistance / 2);
  const halfwayCoordinates = getCoord(halfway);
  halfwayCoordinates[0] = Math.round(halfwayCoordinates[0] * 10000) / 10000;
  halfwayCoordinates[1] = Math.round(halfwayCoordinates[1] * 10000) / 10000;
  return halfwayCoordinates;
}

// Expects coordinates in [lng, lat] order
export function adjustCoordinatesForMapbox(coordinates) {
  const adjustedCoordinates = [];
  let lastLng;
  let adjust = 0;
  coordinates.forEach((coord) => {
    // Round them a bit
    const thisCoord = [
      Math.round(coord[0] * 10000) / 10000,
      Math.round(coord[1] * 10000) / 10000,
    ];
    // Adjust for the dateline mess in leaflet
    let lng = thisCoord[0];
    if (lastLng != null) {
      if (lng - lastLng > 180) {
        adjust -= 360; // Update the adjustment to be made
      } else if (lng - lastLng < -180) {
        adjust += 360; // Update the adjustment to be made
      }
      lng += adjust;
    }
    lastLng = thisCoord[0];
    adjustedCoordinates.push([lng, thisCoord[1]]);
  });
  return adjustedCoordinates;
}

/* 
  Returns a series of coordinates in a nice curve.
  oPos and dPos are [lng, lat] arrays
*/
export function getCurvedLine(oPos, dPos) {
  const curve = [];
  const count = 30; // curve is made up from a number of smaller straight lines, this is the number of such lines to draw
  let t, h, h2, lat3, lng3, j, t2;
  let i = 0;
  let inc = 0;

  let lat1 = parseFloat(oPos[1]);
  let lng1 = parseFloat(oPos[0]);
  let lat2 = parseFloat(dPos[1]);
  let lng2 = parseFloat(dPos[0]);

  // do some maths to calculate the curve
  if (lng2 > lng1) {
    if (parseFloat(lng2 - lng1) > 180) {
      if (lng1 < 0) {
        lng1 = parseFloat(180 + 180 + lng1);
      }
    }
  }

  if (lng1 > lng2) {
    if (parseFloat(lng1 - lng2) > 180) {
      if (lng2 < 0) {
        lng2 = parseFloat(180 + 180 + lng2);
      }
    }
  }

  j = 0;
  t2 = 0;
  if (lat2 == lat1) {
    t = 0;
    h = lng1 - lng2;
  } else if (lng2 == lng1) {
    t = Math.PI / 2;
    h = lat1 - lat2;
  } else {
    t = Math.atan((lat2 - lat1) / (lng2 - lng1));
    h = (lat2 - lat1) / Math.sin(t);
  }
  if (t2 == 0) {
    t2 = t + Math.PI / 5;
  }
  h2 = h / 2;
  lng3 = h2 * Math.cos(t2) + lng1;
  lat3 = h2 * Math.sin(t2) + lat1;

  for (i = 0; i < count + 1; i++) {
    curve.push([
      lng1 * B1(inc) + lng3 * B2(inc) + lng2 * B3(inc),
      lat1 * B1(inc) + lat3 * B2(inc) + lat2 * B3(inc),
    ]);
    inc = inc + 1 / count;
  }

  const coordinates = fixCheatedCoordinates(curve);
  return coordinates;
}

// To ensure the curve is created across meridian lines, we need to clean up any invalid longitudes
function fixCheatedCoordinates(coordinates) {
  let adjustedCoordinates = [];
  for (let x in coordinates) {
    let thisCoord = coordinates[x];
    let lng = thisCoord[0];
    if (lng >= 180) {
      lng = lng - 360;
    } else if (lng <= -180) {
      lng = lng + 360;
    }
    adjustedCoordinates.push([lng, thisCoord[1]]);
  }
  return adjustedCoordinates;
}

//quadratic beziér curve functions to draw curves
function B1(x) {
  return 1 - 2 * x + x * x;
}

function B2(x) {
  return 2 * x - 2 * x * x;
}

function B3(x) {
  return x * x;
}
