import Vue from 'vue';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import App from './App.vue';
import router from './router';
import * as Sentry from '@sentry/vue';
import store from './store';
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';
// import 'travellerspoint_ui/dist/travellerspoint_ui.css';
import ToggleButton from 'vue-js-toggle-button';

Sentry.init({
  Vue,
  dsn: 'https://849463f0303b6af03d21031e8ca72354@o4507185243291648.ingest.us.sentry.io/4507185245454336',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Trace 10%
  tracesSampleRate: 1,
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    'localhost:5173',
    'localhost',
    /^https:\/\/www\.travellerspoint\.com\//,
  ],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const datepickerOptions = {
  daysShort: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  colors: {
    selected: '#4fc14f',
    inRange: '#9ddf9d',
    selectedText: '#fff',
    text: '#333',
    inRangeBorder: '#5cd45c',
    disabled: '#fff',
  },
};
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(ToggleButton);
Vue.config.productionTip = false;

const scrollPositions = Object.create(null);

router.beforeEach((to, from, next) => {
  if (document.getElementById('drawer') !== null) {
    scrollPositions[from.name] = document.getElementById('drawer').scrollTop;
  }
  next();
});
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (!auth.loggedIn()) {
//       next({
//         path: '/login',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() // make sure to always call next()!
//   }
// })

router.afterEach((to, from, next) => {
  if (document.getElementById('drawer') !== null) {
    if (typeof scrollPositions[to.name] !== 'undefined') {
      document.getElementById('drawer').scrollTop = scrollPositions[to.name];
    } else {
      document.getElementById('drawer').scrollTop = 0;
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
